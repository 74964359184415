<template>
  <!-- ******************************************************************************** -->
  <!-- ******************************* 新增/修改 题目弹框 ******************************* -->
  <Modal :mask-closable="false" @on-cancel="onCancel" v-model="visible" fullscreen style="overflow: auto">
    <div style="text-align:center">
      <h2>{{ isQuestionAdd ? '新增' : '修改' }}题目</h2>
    </div>
    <div style="text-align:center;margin-top: 40px;">
      <Form ref="questionForm" :model="questionForm" :label-width="100" :rules="ruleValidate">
        <FormItem label="题目类型" prop="addQuestionType">
          <RadioGroup v-model="questionForm.addQuestionType" type="button" @on-change="changeType">
            <Radio :disabled="!isQuestionAdd" :label="item.value" v-for="(item, index) in questionType" :key="index">{{ item.label }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="题干" prop="stem">
          <!--          <Editor v-model="questionForm.stem" placeholder="题干" :on-change="onStemChange" ref="stemEditor1"></Editor>-->
          <RichEditor v-model="questionForm.stem" ref="stemEditor" />
          <div class="form-tip" v-if="questionForm.addQuestionType === 3" style="width: 100%">
            <div style="float: right">
              空格处使用两个下划线和一个序号类似'__1__'的格式标注
            </div>
          </div>
        </FormItem>
        <!-- ******************************* 单选题 ******************************* -->
        <FormItem
          v-for="(item, index) in questionForm.items"
          v-if="questionForm.addQuestionType === 1"
          :key="item.index"
          :label="`选项 ${getChoiceIndexFromNumber(index)}`"
          :prop="'items.' + index + '.value'"
          :rules="{ required: true, message: '请输入选项 ', trigger: 'blur' }"
        >
          <div style="position: relative">
            <RichEditor v-model="item.value" />
            <div class="option-action">
              <Checkbox v-model="item.correct" @on-change="setChecked(index)" />
              设为正解
              <Button type="error" size="small" style="margin-left: 10px" @click="handleRemove(index)">删除</Button>
            </div>
          </div>
        </FormItem>
        <!-- ******************************* 多选题 ******************************* -->
        <FormItem
          v-for="(item, index) in questionForm.items"
          v-if="questionForm.addQuestionType === 2"
          :key="item.index"
          :label="`选项 ${getChoiceIndexFromNumber(index)}`"
          :prop="'items.' + index + '.value'"
          :rules="{ required: true, message: '请输入选项 ', trigger: 'blur' }"
        >
          <div style="position: relative">
            <RichEditor v-model="item.value" />
            <div class="option-action">
              <Checkbox v-model="item.correct" />
              设为正解
              <Button type="error" size="small" style="margin-left: 10px" @click="handleRemove(index)">删除</Button>
            </div>
          </div>
        </FormItem>
        <FormItem v-if="questionForm.addQuestionType === 1 || questionForm.addQuestionType === 2">
          <Row>
            <Button type="primary" @click="handleAdd">
              <svg class="icon spread" aria-hidden="true">
                <use xlink:href="#icon-xinzeng" />
              </svg>
              添加选项
            </Button>
            <!--              <Button @click="analyze" style="margin-left: 10px" v-if="showAnalyze && questionForm.items.length === 1">自动解析</Button>-->
          </Row>
        </FormItem>
        <!-- ******************************* 判断题 ******************************* -->
        <FormItem label="答案" v-if="questionForm.addQuestionType === 0">
          <RadioGroup v-model="questionForm.trueOrFalse">
            <Radio label="true">正确</Radio>
            <Radio label="false">错误</Radio>
          </RadioGroup>
        </FormItem>
        <!-- ******************************* 填空题 ******************************* -->
        <FormItem v-for="(item, index) in questionForm.fillInBlank" v-if="questionForm.addQuestionType === 3" :key="index" :label="'填空'">
          <Row>
            <Col span="16">
              <Input type="text" v-model="item.value" placeholder="一个空格如果有多个答案则用','隔开即可" />
            </Col>
            <Col span="3">
              <a @click="handleRemove(index)" style="color: red;cursor: hand;margin-left: 20px">X</a>
            </Col>
          </Row>
        </FormItem>
        <FormItem v-if="questionForm.addQuestionType === 3">
          <Row>
            <Col span="12">
              <Button style="background-color: #2598E5;color: white" long @click="handleAddfillInBlank">
                <svg class="icon spread" aria-hidden="true">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
                添加填空
              </Button>
            </Col>
          </Row>
        </FormItem>
        <!-- ******************************* 代码题 ******************************* -->
        <FormItem label="代码模板" v-show="questionForm.addQuestionType === 5">
          <JYCodeEditor ref="code-editor" style="border: solid 1px #e3e3e3" :is-add="isQuestionAdd" v-model="questionForm.codeTemplate" />
        </FormItem>
        <!-- ******************************* 简答题 ******************************* -->
        <FormItem label="答案" v-if="questionForm.addQuestionType === 4" prop="answer">
          <Editor v-model="questionForm.answer" placeholder="题目答案" :on-change="onAnswerChange" ref="answerEditor" />
        </FormItem>
        <FormItem label="题目解析" prop="analysis">
          <RichEditor v-model="questionForm.analysis" placeholder="题目解析" :on-change="onAnalysisChange" ref="analysisEditor" />
        </FormItem>
        <FormItem label="知识点" prop="knowledgePoints">
          <!--          <Select v-model="questionForm.knowledgePoints" multiple placeholder="知识点" @on-change="closeSelect" ref="kSelect">-->
          <!--            <Option v-for="(item,index) in knowledgePoint" :key="index+0" :value="item.id">-->
          <!--              {{item.name}}-->
          <!--            </Option>-->
          <!--          </Select>-->
          <!--          <el-cascader ref="cascader" style="width: 100%" v-model="questionForm.knowledgePoints" :props="treeProps" filterable label="name" :options="knowledgePointTree" clearable></el-cascader>-->
          <div class="kInput" @click="openDrawer">
            <div style="flex: auto">
              <Tag
                style="margin: 0 8px 0 0"
                @on-close="closeTag(index)"
                color="#E7EFF7"
                v-for="(item, index) in questionForm.knowledgePoints"
                :key="index"
                closable
                >{{ item.name }}</Tag
              >
            </div>
            <Button
              @click="clearTag"
              v-if="questionForm.knowledgePoints ? (questionForm.knowledgePoints.length === 0 ? false : true) : false"
              shape="circle"
              style="width:56px;height: 22px;line-height: 22px;color: #5E6470;font-size: 12px"
              >清空</Button
            >
          </div>
        </FormItem>
        <FormItem label="题目难度" prop="questionDifficulty">
          <RadioGroup v-model="questionForm.questionDifficulty" type="button">
            <Radio :label="item.value" v-for="(item, index) in difficulty" :key="index">{{ item.label }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="预计完成时间" prop="expectedTime">
          <InputNumber v-model="questionForm.expectedTime" placeholder="完成时间" :max="60" :min="1" /><span style="margin-left: 8px">分钟</span>
        </FormItem>
        <FormItem label="标签" prop="tags">
          <Select
            :class="tags.length === 0 ? 'tag' : ''"
            not-found-text="暂无数据"
            placement="top-start"
            v-model="questionForm.tags"
            filterable
            allow-create
            multiple
            @on-create="handleCreate"
            placeholder="请选择或输入标签"
            style="width: calc(100% - 230px);"
          >
            <Option v-for="(item, index) in tags" :value="item.name" :key="item.name + index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <Upload
          v-if="false"
          multiple
          ref="upload"
          :show-upload-list="false"
          :on-success="handleUploadCoverSuccess"
          :on-progress="handleUploadProgress"
          :before-upload="beforeUpload"
          :on-exceeded-size="handleExceededSize"
          :data="uploadParams"
          :format="['doc', 'docx', 'xls', 'xlsx']"
          :max-size="2048"
          type="drag"
          :action="uploadUrl"
        >
          <Button icon="ios-cloud-upload-outline">上传文件</Button>
        </Upload>
        <FormItem label="审核" prop="status">
          <i-switch v-model="isChecked" />
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button @click="addOrEditQuestion(1)" :loading="saveLoading" :disabled="isChecked">保存草稿</Button>
      <Button @click="onCancel" class="modal-btn">取消</Button>
      <Button @click="addOrEditQuestion(isChecked ? 0 : 2)" class="modal-btn" type="primary" :loading="releaseLoading">发布</Button>
    </div>
    <Drawer title="知识点" v-model="showDrawer" :mask="false">
      <div>
        <el-tree
          node-key="id"
          ref="modalTree"
          @check-change="handleCheckChange"
          :data="knowledgePointTree"
          :props="{ label: 'name' }"
          check-strictly
          show-checkbox
        />
      </div>
    </Drawer>
  </Modal>
</template>

<script>
import { questionType } from '../../../../../util/questionType.js'
import { difficulty } from '../../../../../util/difficulty.js'
import Editor from '../../../../form/Editor'
import questionRepoApi from '../../../../../api/questionRepo.js'
import { mapState } from 'vuex'
import JYIcon from '@components/common/JYIcon'
import RichEditor from '@components/common/RichEditor'
import JYCodeEditor from '@components/common/JYCodeEditor'
import knowledge from '@api/knowledge'
export default {
  name: 'QuestionRepoAddNew',
  props: {
    isQuestionAdd: Boolean,
    value: {
      type: Boolean,
      default: false
    },
    questionBankIds: String,
    modifyQuestionId: String,
    knowledgePointTree: {
      type: Array,
      default: function() {
        return []
      }
    },
    tags: {
      type: Array,
      default: function() {
        return []
      }
    },
    page: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.info.userId
      // knowledgePoint: state => state.knowledge.rootKnowledgeList
    })
  },
  methods: {
    handleCreate(val) {
      this.tags.push({ name: val })
    },
    openDrawer() {
      this.$refs.modalTree.setCheckedNodes(this.questionForm.knowledgePoints)
      this.showDrawer = true
    },
    clearTag() {
      this.questionForm.knowledgePoints = []
      this.$refs.modalTree.setCheckedNodes([])
    },
    closeTag(index) {
      this.questionForm.knowledgePoints.splice(index, 1)
      this.$refs.modalTree.setCheckedNodes(this.questionForm.knowledgePoints)
    },
    handleCheckChange() {
      this.questionForm.knowledgePoints = this.$refs.modalTree.getCheckedNodes()
    },
    analyze() {
      let op = this.questionForm.items[0].value
      let allOp = this.questionForm.items[0].value.toLowerCase()
      let ops = []
      let a = op.substring(6, allOp.indexOf('<p>b'))
      let b = op.substring(allOp.indexOf('<p>b') + 6, allOp.indexOf('<p>c') !== -1 ? allOp.indexOf('<p>c') : allOp.length)
      let c = op.substring(
        allOp.indexOf('<p>c') !== -1 ? allOp.indexOf('<p>c') + 6 : allOp.length,
        allOp.indexOf('<p>d') !== -1 ? allOp.indexOf('<p>d') : allOp.length
      )
      let d = op.substring(
        allOp.indexOf('<p>d') !== -1 ? allOp.indexOf('<p>d') + 6 : allOp.length,
        allOp.indexOf('<p>e') !== -1 ? allOp.indexOf('<p>e') : allOp.length
      )
      let e = op.substring(
        allOp.indexOf('<p>e') !== -1 ? allOp.indexOf('<p>e') + 6 : allOp.length,
        allOp.indexOf('<p>f') !== -1 ? allOp.indexOf('<p>f') : allOp.length
      )
      let f = op.substring(allOp.indexOf('<p>f') !== -1 ? allOp.indexOf('<p>f') + 6 : allOp.length, allOp.length)
      ops = [a, b, c, d, e, f].filter(Boolean)
      ops.map(item => {
        this.add0ps(item)
      })
      this.questionForm.items.splice(0, 1)
      this.showAnalyze = false
    },
    add0ps(val) {
      this.index++
      this.questionForm.items.push({
        value: val,
        correct: false,
        index: this.index
      })
    },
    changeType(val) {
      this.resetForm(false)
      this.questionForm.addQuestionType = val
    },
    closeSelect() {
      this.$refs.kSelect.hideMenu()
    },
    getChoiceIndexFromNumber(num) {
      return String.fromCharCode(num + 65)
    },
    beforeUpload(file) {
      // questionApi.getFileToken(file.name) {
      //
      // }
    },
    handleUploadCoverSuccess(response, file) {
      this.uploadSuccess = true
      this.isUploading = false
      this.fileUrl = `${this.uploadUrl}/${this.uploadParams.key}`
    },
    handleExceededSize() {
      this.$message.error('文件大小不超过 2M')
    },
    handleUploadProgress(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    getData(data) {
      this.questionForm.questionId = data.questionId
      this.questionForm.addQuestionType = data.modifyQuestionType
      this.questionForm.knowledgePoints = data.modifyQuestionKnowledgePoints
      this.questionForm.questionDifficulty = data.modifyQuestionDifficulty
      this.questionForm.expectedTime = data.modifyQuestionExpectedTime
      this.questionForm.stem = data.modifyQuestionStem
      this.questionForm.analysis = data.modifyQuestionAnalysis
      this.isChecked = data.status === 0
      if (data.modifyQuestionCodeTemplate) {
        this.$nextTick(() => {
          this.$refs['code-editor'].getData(data.modifyQuestionCodeTemplate)
        })
      }
      // if (data.modifyQuestionCodeTemplate) {
      //   this.$nextTick(() => {
      //     this.$refs['code-editor'].setContent(data.modifyQuestionCodeTemplate)
      //     this.questionForm.codeTemplate = data.modifyQuestionCodeTemplate
      //   })
      // }

      if (data.modifyQuestionOptions !== undefined) {
        this.questionForm.items = []
        for (let i = 0; i < data.modifyQuestionOptions.length; i++) {
          this.questionForm.items.push({
            value: data.modifyQuestionOptions[i].content,
            index: i,
            status: 1,
            correct: data.modifyQuestionOptions[i].correct
          })
        }
      }
      if (data.modifyQuestionBlanks !== undefined) {
        this.questionForm.fillInBlank = []
        for (let i = 0; i < data.modifyQuestionBlanks.length; i++) {
          this.questionForm.fillInBlank.push({
            value: data.modifyQuestionBlanks[i].blankAnswer.join(',') || [],
            index: i
          })
        }
      }
      if (data.modifyQuestionAnswer !== undefined) {
        this.questionForm.trueOrFalse = data.modifyQuestionAnswer.toString()
      }
      this.$refs.stemEditor.modifyEditor(data.modifyQuestionStem)
      this.$refs.analysisEditor.modifyEditor(data.modifyQuestionAnalysis)
      if (this.questionForm.addQuestionType === 4) {
        this.$nextTick(() => {
          this.$refs.answerEditor.modifyEditor(data.modifyQuestionAnswer)
        })
      }
      if (this.questionForm.knowledgePoints.length !== 0) {
        let key = this.questionForm.knowledgePoints.map(item => {
          return item.knowledgeId
        })
        this.$refs.modalTree.setCheckedKeys(key)
        this.questionForm.knowledgePoints = this.$refs.modalTree.getCheckedNodes()
      } else {
        this.$refs.modalTree.setCheckedNodes([])
      }
      if (data.tags.length !== 0) {
        this.questionForm.tags = data.tags.map(item => {
          return item.name
        })
      }
    },
    onAnalysisChange(content) {
      this.questionForm.analysis = content
    },
    onAnswerChange(content) {
      this.questionForm.answer = content
    },
    handleAddfillInBlank() {
      this.index++
      this.questionForm.fillInBlank.push({
        value: '',
        index: this.index
      })
    },
    handleAdd() {
      this.index++
      this.questionForm.items.push({
        value: '',
        correct: false,
        index: this.index
      })
    },
    handleRemove(index) {
      const newQuestionForm = { ...this.questionForm }
      if (newQuestionForm.items[index]) {
        newQuestionForm.items.splice(index, 1)
      }
      if (newQuestionForm.fillInBlank[index]) {
        newQuestionForm.fillInBlank.splice(index, 1)
      }
      this.questionForm = newQuestionForm
    },
    setChecked(index) {
      for (let i in this.questionForm.items) {
        if (i != index) {
          this.questionForm.items[i].correct = false
        }
      }
    },
    onCancel() {
      this.showDrawer = false
      this.resetForm(true)
      this.$refs.stemEditor.clearEditor()
      this.$refs.analysisEditor.clearEditor()
      if (this.questionForm.addQuestionType === 4) {
        this.$refs.answerEditor.clearEditor()
      }
      if (this.questionForm.addQuestionType === 5) {
        this.$refs['code-editor'].clearEditor()
      }
      this.visible = false
      this.$emit('changeShowAddQuestionModal', false)
    },
    addOrEditQuestion(status) {
      this.$refs.questionForm.validate(valid => {
        if (valid) {
          if (this.questionForm.stem === '') {
            this.$message.error('请输入题干')
          } else if (this.questionForm.addQuestionType === 1 || this.questionForm.addQuestionType === 2) {
            let a = []
            let b = []
            this.questionForm.items.map(item => {
              a.push(item.correct)
              b.push(item.value)
            })
            if (this.questionForm.items.length < 2) {
              this.$message.error('选择题至少有2个选项')
              return
            }
            let isEmpty = b.map((i, index) => {
              if (i.length === 0 || i === '<br>') {
                this.$message.error(`选项${this.getChoiceIndexFromNumber(index)}不能为空`)
                return true
              } else {
                return false
              }
            })
            if (isEmpty.indexOf(true) > -1) {
              return
            }
            if (a.indexOf(true) === -1) {
              this.$message.error('请选择正确选项')
              return
            }
            this.createOrEdit(status)
          } else {
            this.createOrEdit(status)
          }
        }
      })
    },
    createOrEdit(status) {
      let questionId = parseInt(this.questionBankIds)
      var options = []
      for (let i = 0; i < this.questionForm.items.length; i++) {
        options.push({
          content: this.questionForm.items[i].value,
          correct: this.questionForm.items[i].correct
        })
      }
      var questionBlank = []
      for (let i = 0; i < this.questionForm.fillInBlank.length; i++) {
        questionBlank.push({
          blankAnswer: this.questionForm.fillInBlank[i].value.split(','),
          blankId: '__' + (i + 1) + '__'
        })
      }
      if (this.questionForm.addQuestionType === 0) {
        var answer = this.questionForm.trueOrFalse
      } else {
        answer = this.questionForm.answer
      }
      let ids = this.questionForm.knowledgePoints.map(item => {
        return item.id
      })
      let tag = []
      this.questionForm.tags.forEach(item => {
        tag.push({ name: item })
      })
      let stem = this.cleanWord(this.questionForm.stem)
      const payload = {
        questionType: this.questionForm.addQuestionType,
        knowledgePointIds: ids,
        expectedTime: this.questionForm.expectedTime,
        stem: stem,
        analysis: this.questionForm.analysis,
        questionBankId: questionId,
        authorId: this.userId,
        difficulty: this.questionForm.questionDifficulty,
        answer: answer,
        options: options,
        blanks: questionBlank,
        fileUrl: this.fileUrl,
        codeQuestionTemplates: this.questionForm.codeTemplate,
        // template:
        //   this.questionForm.addQuestionType === 5
        //     ? this.questionForm.codeTemplate
        //     : '',
        status: status,
        tags: tag
      }
      status === 1 ? (this.saveLoading = true) : (this.releaseLoading = true)
      if (this.isQuestionAdd === true) {
        questionRepoApi
          .addQuestion(payload)
          .then(res => {
            if (!isNaN(res.res)) {
              this.close()
              this.$message.success(status === 1 ? '保存草稿成功' : '新建成功')
              this.$parent.getAllQuestion(0, 0)
            }
            // else {
            //   this.$message.error(res.res)
            // }
            if (status === 1) {
              this.saveLoading = false
            } else {
              this.releaseLoading = false
            }
          })
          .catch(() => {
            if (status === 1) {
              this.saveLoading = false
            } else {
              this.releaseLoading = false
            }
          })
      } else {
        questionRepoApi
          .modifyQuestion(this.modifyQuestionId, payload)
          .then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.$emit('refreshQuestionList')
            }
            this.close()
            // this.$parent.getAllQuestion(this.page, this.size)
            if (status === 1) {
              this.saveLoading = false
            } else {
              this.releaseLoading = false
            }
          })
          .catch(() => {
            if (status === 1) {
              this.saveLoading = false
            } else {
              this.releaseLoading = false
            }
          })
      }
    },
    resetForm(bool) {
      this.questionForm = {
        addQuestionType: 1,
        knowledgePoints: !bool ? this.questionForm.knowledgePoints : [],
        questionDifficulty: !bool ? this.questionForm.questionDifficulty : 0,
        expectedTime: !bool ? this.questionForm.expectedTime : 1,
        stem: !bool ? this.cleanWord(this.questionForm.stem) : '',
        answer: '',
        analysis: !bool ? this.questionForm.analysis : '',
        items: [],
        trueOrFalse: 'true',
        fillInBlank: [],
        tags: !bool ? this.questionForm.tags : [],
        codeTemplate: []
      }
      this.$refs['questionForm'].fields.forEach(e => {
        if (e.prop === 'knowledgePoints' || e.prop === 'stem') {
          e.resetField()
        }
      })
      localStorage.removeItem('newForm')
    },
    close() {
      this.$refs.modalTree.setCheckedNodes([])
      this.showDrawer = false
      this.visible = false
      this.$emit('on-change-visible')
      this.resetForm()
      this.$refs.stemEditor.clearEditor()
      this.$refs.analysisEditor.clearEditor()
      if (this.questionForm.addQuestionType === 4) {
        this.$refs.answerEditor.clearEditor()
      }
      if (this.questionForm.addQuestionType === 5) {
        this.$refs['code-editor'].clearEditor()
      }
    }
  },
  watch: {
    value(val) {
      this.visible = val
    },
    questionForm: {
      handler(newVal) {
        localStorage.setItem('newForm', JSON.stringify(newVal))
      },
      deep: true
    }
  },
  data: function() {
    // const validateQuestion = (rule, value, callback) => {
    //   let reg = /^(?=.*\S).+$/
    //   if (!value) {
    //     that.ruleValidate.stem.error = true
    //     callback(newPaper Error('题干不能为空'))
    //   } else if (!reg.test(value)) {
    //     that.ruleValidate.stem.error = true
    //     callback(newPaper Error('题干不能全为空格'))
    //   } else {
    //     callback()
    //   }
    // }
    // const that = this
    return {
      saveLoading: false,
      releaseLoading: false,
      showDrawer: false,
      treeProps: {
        expandTrigger: 'hover',
        multiple: true,
        checkStrictly: true,
        label: 'name',
        value: 'id'
      },
      showAnalyze: true,
      ruleValidate: {
        addQuestionType: { required: true },
        questionDifficulty: { required: true },
        expectedTime: { required: true, message: '请输入时间' },
        stem: {
          required: true,
          message: '请输入题干'
          // validator: validateQuestion
        }
      },
      fileUrl: '',
      isUploading: false,
      percentage: 0,
      uploadUrl: '',
      uploadSuccess: false,
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      visible: this.value,
      index: 1,
      questionType: questionType,
      difficulty: difficulty,
      questionForm:
        localStorage.getItem('newForm') === null || this.isQuestionAdd
          ? {
              addQuestionType: 1,
              knowledgePoints: [],
              questionDifficulty: 0,
              expectedTime: 1,
              stem: '',
              answer: '',
              analysis: '',
              items: [],
              trueOrFalse: 'true',
              fillInBlank: [],
              tags: [],
              codeTemplate: []
            }
          : JSON.parse(localStorage.getItem('newForm')),
      isChecked: false
    }
  },
  components: {
    Editor,
    JYIcon,
    RichEditor,
    JYCodeEditor
  }
}
</script>

<style scoped lang="less">
.option-action {
  position: absolute;
  right: 20px;
  top: 5px;
  display: flex;
  align-items: center;
}
::v-deep .ivu-tag {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  margin: 2px 8px 2px 0;
  font-size: 15px;
}
/*::v-deep .ivu-select-dropdown {*/
/*  z-index: 10009;*/
/*}*/
::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
  color: white;
  border-color: #2d8cf0;
  background-color: #2d8cf0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper {
  &:hover {
    color: inherit;
  }
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
  &:hover {
    color: white;
  }
}
.kInput {
  cursor: pointer;
  min-height: 50px;
  width: calc(100% - 230px);
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(234, 237, 241, 1);
  opacity: 1;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;

  ::v-deep .ivu-tag {
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;

    .ivu-tag-text {
      color: #415e8d !important;
    }

    .ivu-icon {
      color: #415e8d !important;
    }
  }
}
::v-deep .ivu-drawer-body {
  padding: 10px 4px 10px 10px;
}
::v-deep .ivu-drawer-wrap {
  z-index: 10003;
}
::v-deep .el-tree {
  .el-tree-node__content {
    display: block !important;
  }
  .el-tree-node__children {
    overflow: visible !important;
  }
}
.tag ::v-deep .ivu-select-not-found {
  display: block !important;
}
/*::v-deep .ivu-modal-wrap {*/
/*  z-index: 10002 !important;*/
/*}*/
</style>
